<template>


  <div >
    <header >
       <br>
  </header>
<!-- <button style="float: left;margin-top:20; margin-right:20px" type="button" class="btn btn-success" @click.prevent= "Getallmtime(currentUser)">تقرير موظف</button> -->
<!-- <button style="float: left;margin-top:20; margin-right:120px" type="button" class="btn btn-success" @click.prevent= "Getallmtime(0)">Current Month</button> -->
<!-- <button style="float: left;margin-top:20; margin-right:120px" type="button" class="btn btn-success" @click.prevent= "Getallmtime(2)">-2 Month</button>
<button style="float: left;margin-top:20; margin-right:120px" type="button" class="btn btn-success" @click.prevent= "Getallmtime(3)">-3 Month</button> -->
<!-- <button style="float: left;margin-top:20; margin-right:20px" type="button" class="btn btn-success" @click.prevent= "generatepdf()">KPIs</button> -->
<!-- <button style="float: left;margin-top:20; margin-right:20px" type="button" class="btn btn-success" @click.prevent= "getreport1()"> Failures PDF</button> -->
<!-- <input type='number' v-model="empid"> </input>
<h1> {{currentUser}} </h1> -->
<!-- <downloadexcel
      class = "btn"
       :header= "[$store.state.machineName+' Effieciency Report month  '+ lastMonth+'-'+y ,'PEI= ' + PEI]"
       :title="['wwww']"
      :fetch   = "reportex"
      :fields = "json_fields"
      type    = "xls">
    <!-- <button  img ="images/excel1.png"  style="float: left;margin-top:20; margin-right:20px" type="button" class="btn btn-success"> Excel</button> -->
    <!-- <h3> Export Excel</h3> -->
   <!-- <input type="image" src="images/excel1.png" alt="Excel" width="50" height="40">  -->
       <!-- <button img src="excel1.png" >Download Excel </button> -->
    <!-- </downloadexcel> -->

      <br>


     <div style="width: 900px;width:100%"  class="jumbotron" >
  <table name='tbl1' id='tbl1' class="table">
    <thead class="thead-dark" >
      <th scope="col">رقم السركي</th>
      <th scope="col">الاسم</th>
       <th scope="col"> تاريخ الميلاد </th>
      <th scope="col"> تاريخ المؤقت </th>
      <th scope="col"> تاريخ الدائم </th>
       <th scope="col"> تاريخ الدرجه </th>
       <th scope="col"> الوظيفة </th>
      <th scope="col"> الاداره </th>
      <th scope="col"> القطاع </th>
      </thead>
  <td>  {{ sid }} </td>
  <td>  {{ name}} </td>
  <td>  {{bdate | formatDateOnly}} </td>
   <td>  {{tdate  | formatDateOnly}}  </td>
  <td>  {{cdate  | formatDateOnly }} </td>
   <td>  {{degreedate  | formatDateOnly}} </td>
  <td>  {{wazifa  }} </td>
  <td>  {{edara }} </td>
   <td>  {{sector  }} </td>
    </table>

 <table name='tbl1' id='tbl1' class="table">
    <thead class="thead-dark" >
      <th scope="col">رقم الملف</th>
      <th scope="col">رصيد أجازات</th>
       <th scope="col"> الرقم التامنى </th>

      </thead>
  <td>  {{ fileno }} </td>
  <td>  {{ r_morahal}} </td>
  <td>  {{tno }} </td>

    </table>
    </div>

</div>
</template>
<script>
import moment from 'moment';
import downloadexcel from "vue-json-excel";
import JsonExcel from 'vue-json-excel'
import jsPDF from 'jspdf';
//import 'jspdf-customfonts';
import {Amiri_Normal} from "../assets/fonts/Amiri-Regular-normal.js";
import 'jspdf-autotable';
import TabMenue from "@/components/TabMenue";
import MessagesTable from "@/components/MessagesTable";
import MessagesDrop from "@/components/MessagesDrop";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import ReportService from "../services/report.service";
import authHeader from "../services/auth-header";
import axios from "axios";
import User from "../models/user";

import { BBL } from "../services/variables.js"
let user = new User("", "");

var numeral = require("numeral");

export default {
  name: "Messages",

  data() {
    return {
        json_fields: {
          'ID': 'id',
          'Message': 'payload',
          'Date': 'date',
         'Time': 'time',
          'End Date':'fdate',
         'End_Time' :  'ftime',
          'Period': 'fperiod',
          'OP Comment': 'Comment',
          'M Comment' :'mcomment',
          'Operator': 'empno',
          'fdesc': 'fdesc',
          'Cat':'fcat',
           'Subcat':'fsubcat',
      },
      empid:'',
      PPDmanual:'',
      offtime:'',
      ontime:'',
      runtime:'',
      stoptime:'',

      ppdconverter:1,
      content: {},
      lastMonth:'',
      y:'',
      cats:{},
      subcats:{},
      failures:{},
      sid:'',
      r_agz:'',
      r_agz_prev:'',
      fileno:'',
      r_maly:'',
      r_morahal:'',
      name:'',
      bdate:'',
      tdate:'',
      cdate:'',
      mdate:'',
      m:'',
      wazifa:'',
      degreedate:'',
      edara:'',
      sector:'',
      idno:'',
      tno:'',
      mothername:'',
      address:'',
      PCLHF2:'',
      RCLHF1:'',
      RCLHF2:'',
      RCLHF3:'',
      available:'',
      max:'',
      RCLHFO:'',
      CLOSED:'',
      qf:'',
      LTC1:'',
      LTC2:'',
      CHOcount:'',
    };
  },
   components:{
  MessagesTable,MessagesDrop,TabMenue, downloadexcel
  },
  created(){
  // this.GetBBL(this.$store.state.vardeleyMuliplier);
  // this.GetPPD(this.$store.state.vardelayCarton);
  // this.Getit(0);
  // this.Getallmtime(0);
  setTimeout(()=>this.Getallmtime(this.currentUser),1000);
  },
   mounted () {

  setTimeout(()=>this.Getallmtime(this.currentUser),1000);

   },
computed:{

  currentUser() {
      return this.$store.state.auth.user.username;
    },
},
  methods:{
    reportex () {
   const gg=  this.content.map(function(hobby) {
    return {
         id: hobby.id,
        payload: hobby.payload,
        date: moment(String(hobby.date)).format('DD-MM-YYYY'),
        time:moment(String(hobby.date)).format('HH:mm:ss'),
        fdate:moment(String(hobby.ftime)).format('DD-MM-YYYY'),
        ftime:moment(String(hobby.ftime)).format('HH:mm:ss'),
        fperiod: hobby.fperiod,
        Comment: hobby.Comment,
        mcomment:hobby.mcomment,
        empno: hobby.empno,
        fdesc:hobby.fdesc,
        fcat :hobby.fcatcode,
        fsubcat:hobby.fsubcat,  //Jams

           }
        });
   return  gg
  },
    async fetchData(){
      // const response = await axios.get('https://holidayapi.com/v1/holidays?key=a4b2083b-1577-4acd-9408-6e529996b129&country=US&year=2017&month=09');
      // console.log(response);
      var dd=[
        { colA: "Hello", colB: "World" },
        {
          colA: "Multi-line",
          /* Multi-line value: */
          colB:
            "This is a long paragraph\nwith multiple lines\nthat should show in a single cell."
        },
        { colA: "Another", colB: "Regular cell" }
      ]
      return dd;
    },
startDownload(){
        alert('Export failure to Excel sheet');
    },
finishDownload(){
        alert('Exporting Is Finished');
    }  ,
Getit(m){

    UserService.getemp(m).then(res => {
    this.content = res.data;
    console.log(this.content);
           })
      .catch(error => {
        console.error(error);
      });
 },
GetBBL(mid){
    ReportService.getmaintmode(mid).then(res => {
    this.BBL = res.data[0].maintmode
    console.log(this.maintmode);
           })
      .catch(error => {
        console.error(error);
      });
     },
GetPPD(mid){
    ReportService.getmaintmode(mid).then(res => {
    this.ppdconverter = res.data[0].maintmode
    console.log(this.maintmode);
           })
      .catch(error => {
        console.error(error);
      });
     },
 getreport1(){

let jsObject = JSON.stringify(this.content);
var doc = new jsPDF();
doc.addFileToVFS('Amiri-Regular.ttf',Amiri_Normal);
doc.addFont('Amiri-Regular.ttf', 'custom', 'normal');
doc.setFont('custom');

doc.autoTable({doc:'#my-table'});

doc.text('Failures Report ('+this.$store.state.machineName+'): '+ this.lastMonth +' / ' + this.y,11,11) ;
var columns = ['ID','Message','Date','Time','End Date','End Time','Period','Op Comment','Mcomment','Operator','Fault','Cat'];
  doc.setFontSize(8);
doc.addFileToVFS('Amiri-Regular.ttf',Amiri_Normal);
doc.addFont('Amiri-Regular.ttf', 'custom', 'normal');
doc.setFont('custom');
  doc.autoTable({

   styles: {cellPadding: 0.5, fontSize: 8,font:'custom',fontStyle: 'normal',} ,
    columns,
    body:this.report11 ,
    tableLineWidth:.5
    ,theme:'grid'
    , margin: {left: 1,right:1}
    ,headStyles: {fontSize: 8}
    , styles: {cellPadding: 0.5, fontSize: 8}
      });

//doc.setFontSize(12);
doc.save('R'+ this.lastMonth +'-' + this.y)
 },

 Getallmtime(m){
//  UserService.getftime(d1.mid,d1.vid).then(res => {
//     this.ftime = res.data[0].ftime;
//     this.fsecond = res.data[0].fsecond;
//            })
//       .catch(error => {
//         console.error(error);
//       });
//      },

    UserService.getemp(m).then(res => {
      this.sid=res.data[0].sid ;
      this.r_agz=res.data[0].r_agz;
      this.r_agz_prev=res.data[0].r_agz_prev;
      this.fileno=res.data[0].fileno;
      this.r_maly=res.data[0].r_maly;
    this.r_morahal = res.data[0].r_morahal;
    this.name = res.data[0].name;
    this.bdate= res.data[0].bdate;
     this.tdate=res.data[0].tdate;
      this.cdate = res.data[0].cdate;
       this.mdate = res.data[0].mdate;
        this.m= res.data[0].m;
         this.wazifa = res.data[0].wazifa;
          this.degreedate = res.data[0].degreedate;
           this.edara = res.data[0].edara;
            this.sector = res.data[0].sector;
              this.idno = res.data[0].idno;
               this.tno = res.data[0].tno;
                this.mothername = res.data[0].mothername;
                  this.address = res.data[0].address;

      })
      .catch(error => {
        console.error(error);
      });
     },
     //
generatepdf(){
const doc = new jsPDF()
doc.text('Performance Report ('+this.$store.state.machineName+'): '+ this.lastMonth +' / ' + this.y,15,15) ;
doc.autoTable({
  head: [['Multiplier ']],
  body: [
    [(this.BBL ) ],
  ],
})

doc.autoTable({
  head: [['RCL', 'PCL', 'PEI','LTC','CHO Count','CHO Time']],
  body: [
    [numeral(this.RCLTOTAL).format("0.00") +' BBL', numeral(this.PCL ).format("0.00")+' BBL', (numeral(this.PEI *100 ).format("0.00")) +' %',numeral(this.LTC1+this.LTC2 ).format("0.00")+' BBL',this.CHOcount,numeral(this.CHO).format("0.00")+' hr'],
  ],
})
 //
doc.autoTable({
  head: [['Quality-Factor', 'Max', 'Closed','Actual','Available']],
  body: [
    [numeral(this.qf ).format("0.00")+' BBL', numeral(this.max ).format("0.00") +' BBL', numeral(this.CLOSED * this.BBL ).format("0.00")+' BBL',numeral(this.PPD).format("0.00")+' BBL',numeral(this.available  ).format("0.00")],
  ],
})
doc.autoTable({
  head: [['Maintenance Reliability', 'External Factor', 'RCL by time','RCL Human','Other RCL Human']],
  body: [
    [numeral(this.MR ).format("0.00")+' BBL', numeral(this.EF ).format("0.00") +' BBL', numeral(this.RCL / this.BBL ).format("0.00")+' hr',numeral(this.RCLHF).format("0.00")+' hr',numeral(this.RCLHFO).format("0.00")+' hr'],

  ],
})
//
doc.autoTable({
  head: [['Planned Maintenance', 'Other Planned Events', 'PCL Human']],
  body: [
    [(numeral(this.PL).format("0.00") )+' BBL', numeral(this.OPL  ).format("0.00") +' BBL', numeral(this.PCLHF ).format("0.00")+' hr'],

  ],
})
doc.autoTable({
  head: [['Manual Entry', 'Actual + Manual', 'rejected','Off Time','On Time']],
  body: [
    [(numeral(this.PPDmanual).format("0.00") )+' BBL', numeral(this.PPD +this.PPDmanual).format("0.00") +' BBL', numeral(this.PPD-this.PPDmanual ).format("0.00")+' BBL',numeral(this.offtime).format("0.00")+' hr',numeral(this.ontime).format("0.00")+' hr'],

  ],
})
doc.save('PR'+ this.lastMonth +'-' + this.y)
},
gen2(){
  const pdf = new jsPDF();
      let header = ["id","name"];
      let headerConfig = header.map(key=>({ 'name': key,
      'prompt': key,
      'width':50,
      'align':'center',
      'padding':0}));
     let data = [{id: 'ww', name: "Peter"},{id: 'ff', name: "Chris"}];
     // let data=this.content;
      pdf.table(20, 30, data, headerConfig);
      pdf.save("pdf.pdf");
},
     //
     Getallftime(){
    var d1={mid:'',vid:''};
    d1.mid=this.$store.state.midDi0;
    d1.vid=1;
    UserService.getftime(d1.mid,d1.vid).then(res => {
    this.ftime = res.data[0].ftime;
    this.fsecond = res.data[0].fsecond;
           })
      .catch(error => {
        console.error(error);
      });
     },
/////
     },
      };







</script>
